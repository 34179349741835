import { default as indexeprehpgVzuMeta } from "/var/www/sites/auth.pos2.halifax.dk/releases/20241113061111/pages/index.vue?macro=true";
import { default as _91token_93j1o3aBhnUbMeta } from "/var/www/sites/auth.pos2.halifax.dk/releases/20241113061111/pages/password/reset/[token].vue?macro=true";
import { default as indexepqIRbaEDCMeta } from "/var/www/sites/auth.pos2.halifax.dk/releases/20241113061111/pages/password/reset/index.vue?macro=true";
import { default as redirectO28mkeckllMeta } from "/var/www/sites/auth.pos2.halifax.dk/releases/20241113061111/pages/redirect.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/auth.pos2.halifax.dk/releases/20241113061111/pages/index.vue")
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    component: () => import("/var/www/sites/auth.pos2.halifax.dk/releases/20241113061111/pages/password/reset/[token].vue")
  },
  {
    name: "password-reset",
    path: "/password/reset",
    component: () => import("/var/www/sites/auth.pos2.halifax.dk/releases/20241113061111/pages/password/reset/index.vue")
  },
  {
    name: "redirect",
    path: "/redirect",
    component: () => import("/var/www/sites/auth.pos2.halifax.dk/releases/20241113061111/pages/redirect.vue")
  }
]